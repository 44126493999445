import { Github } from "styled-icons/boxicons-logos/Github"
import { Twitter } from "styled-icons/boxicons-logos/Twitter"
import { Instagram } from "styled-icons/boxicons-logos/Instagram"
import { Npm } from "styled-icons/fa-brands/Npm"
import { StackOverflow } from "styled-icons/boxicons-logos/StackOverflow"
import { Linkedin } from "styled-icons/boxicons-logos/Linkedin"

const Icons = {
  Github,
  Twitter,
  Instagram,
  Npm,
  StackOverflow,
  Linkedin,
}

export default Icons
