const links = [
  {
    label: "Github",
    url: `https://github.com/assuncaocharles`,
  },
  {
    label: "Twitter",
    url: `https://twitter.com/assuncaocharles`,
  },
  {
    label: "Instagram",
    url: `https://www.instagram.com/charles_assuncao`,
  },
  {
    label: "Npm",
    url: `https://www.npmjs.com/~assuncaocharles`,
  },
  {
    label: "StackOverflow",
    url: `https://stackoverflow.com/users/5046129/charles-assuncao`,
  },
  {
    label: "Linkedin",
    url: `https://www.linkedin.com/in/charlesassuncao/`,
  },
]

export default links
